@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  /* text-transform: capitalize; */
  scrollbar-width: 0px;
}

body {
  background-color: white !important;
}


* {
  font-family: 'Public Sans', sans-serif;
  /* text-transform: capitalize !important; */

}

.table *{
  text-transform: capitalize;
}

body,
html,
#root {
  height: 100%;
  width: 100%;
}